/**
*  --------------------------------
*  project/BASE
*  --------------------------------
*/

@import 'vendors/reset.scss';

@import 'utils/mixins.scss';

@import 'shared/fonts.scss';
@import 'shared/grid.scss';
@import 'shared/animation.scss';
@import 'shared/icons.scss';      
@import 'shared/global.scss';
@import 'shared/fixes.scss';

@import 'modules/section.scss';

@import 'components/logo.scss';
@import 'components/form.scss';
@import 'components/contact.scss';
@import 'components/img.scss';
@import 'components/button.scss';
@import 'components/link.scss';
@import 'components/nav/index.scss';
@import 'components/article.scss';

// Fonts
@mixin small-title-ventures() {
    font-size: .815rem;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: .1em;
    font-variant: small-caps;


    @media #{$mqLarge} {
        font-size: 1rem;    
    }
}


//temporary ventures pages CSS
.wrap {
    max-width: 1310px;
}
.bg {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-repeat:  no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    background-color: $black;

    &[data-background-id="intro"] {
        background-image: url($imgPath + '/background/bg-ventures-intro.jpg');
    }
    &[data-background-id="outro"] {
        background-image: url($imgPath + '/background/bg-ventures-outro.jpg');
        opacity: 0;
    }
}
.intro, .outro {
    height: 100vh;
    display: flex;
    padding-bottom: 0;
    justify-content: center;
    // margin-top: -120px;
}
.intro {
    .icon {
        position: fixed;
        bottom: 30px;
        left: 50%;
        transition: opacity .3s ease-out;
        overflow: visible;
        &:after {
            transform-origin: center;
            transform: translateX(-50%) rotate(90deg);
            display: block;
            font-size: 1.9rem;
            color: $white;
        }
    }
    .ventures-nav-fixed & {
        .icon {
            opacity: 0;
        }
    }
}
@media #{$mqMobile} {
    .section {
        padding-bottom: 10px;
        overflow: hidden;
    }
}
.logo {
    position: relative;
    left: auto;
    width: auto;
    height: auto;
    svg:not(.logo__tagline) {
        width: 140px;
        height: 57px;
        transition: .3s width ease-out;
    }
    @media #{$mqMobile} {
        svg:not(.logo__tagline) {
            width: 110px;
            margin-left: -12px;
        }
    }
}

.header {
    
    background: transparent;
    position: fixed;
    z-index: 99;
    padding-top: 30px;
    padding-bottom: 30px;
    width: 100%;
    transition: padding .3s ease-out, background .3s ease-out;

    .wrap {
        display:flex;
        justify-content: space-between;
        position: relative;
        align-items: center;
    }
    .nav--venture {
        position: relative;
        .footer__social {
            position: absolute;
            top: calc(100% + 30px);
            left: 0;
            width: 100%;
            align-items: center;
        }
        .btn--round {
            margin-right: 10px;
        }
    }

    .ventures-nav-fixed & {
        background: black;
        overflow: hidden;
        padding-top: 15px;
        padding-bottom: 15px;

        .logo svg:not(.logo__tagline) {
            width: 100px;
            height: 40px;
        }
        
    }
}
.footer__social {
    display: flex;
    justify-content: space-around;
}
.btn--round {
    background: $white;
    color: $black;
    display: inline-block;
    padding: 13px 30px 10px;
    border-radius: 20px;
    @include small-title-ventures();
    line-height: 1;
    transition: all .3s ease-out;

    
    @include hover() {
        background: $grayDark;
        color: $white;
    }
}
.btn--arrow {
    display:flex;
    align-items: center;
    transition: all .3s ease-out;
    color: $gray;
    @include icon--arrow-right('after');
    &:after {
        font-size:.3em;
        padding-left: 30px;
        padding-top: 7px;
        transition: all .3s ease-out;
    }

    @include hover() {
        opacity: .4!important;
        &:after {
            padding-left: 40px;
        }
    }
    @media #{$mqMobile} {  
        > *, &:after  {
            flex: 0 1 auto;
        }

        &:after {
            padding-left: 10px;
        }

        @include hover() {
            
            &:after {
                padding-left: 20px;
            }
        }
    }
}

.two_col {
    display: flex; 
    flex-direction: row;
    > .col {
        flex-basis: 66.66666%;
        // padding-left: 120px;
        display: flex;
        flex-wrap: wrap;
        .img-wrapper {
            width: 50%;
            @include keep-ratio('1/1');
        }
        &:first-of-type {
            flex-basis: 33.3333%;
           .rich-text {
               width: 100%;
           }
        }

        > .col {
            width: 50%;
            padding-right: 60px;
            padding-bottom: 60px;

            .heading--normal {
                margin-bottom: $largePadding;
            }

            &:nth-child(even){
                padding-left: 60px;
                padding-right: 0;
            }
        }
    }
    @media #{$mqTablet} {
        > .col {
            > .col {
                padding-right: 30px;
                padding-bottom: 30px;

                &:nth-child(even){
                    padding-left: 30px;
                    padding-right: 0;
                }
            }
        }
    }
    @media #{$mqMobile} {
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: flex-start;
        justify-content: flex-start;
        > .col {
            flex-basis: auto;
            // flex-direction: column;
            &:first-of-type {
                flex-basis: auto;
                width: 100%;
                margin-bottom: 30px;
            }
            .heading--small {
                margin-top: $largePadding;
            }
            > .col {
                flex-basis: 100%;

                padding: 0;
                // margin-bottom: $smallPadding;

                .heading--normal {
                    margin-bottom: $smallPadding;
                }
                &:nth-child(even) {
                    padding: 0;

                }
            }
        }
    }
}
.about {
    > .col .img-wrapper {
        margin-top: $largePadding;
        width: 70%;
        @include keep-ratio('608/174');
    }
}

.intro + section {
    // margin-top: -100px;
    padding-top: 120px;
}

.heading--small {
    @include small-title-ventures();
    font-weight: normal;

    span { font-weight: bold;}

    @media #{$mqMobile} {   
        font-weight: bold;
    }
}

.paragraph--large {
    font-size: 1.5rem;
    margin-top: -5px;
    @media #{$mqMobile} {
        font-size: 1.2em;
    }
}
.team, .committee, .portfolio {
    
    >.col {
        >.col {
            img {
                // width: 100%;
                // margin-bottom: 40px;
            }
            .heading--normal {
                margin-top: 40px;
                margin-bottom: 5px;
            }
            .heading--small {
                // margin-bottom: 40px;
                line-height: 1.3;
                // margin-bottom: 5px;
            }
            p {
                margin-top: 40px;
            }
        } 
    }
    @media #{$mqMobile} { 
        > .col >.col {
            margin-bottom: 30px;
            .heading--small {
                margin-top: 0;
            }
        }
    }
    .img--linkedin {
        position: relative;
        display:block;
        overflow: hidden;
        &:before {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            background: $black;
            position: absolute;
            top: 0;
            left: 0;
        }
        @include icon--linkedin('');

        &:after {
            position: absolute;
            bottom: -30px;
            left: 10px;
            transition: .4s bottom ease-out;
        }

        img {
            width: 100%;
        }
    }

    .img {
        
        img {
            display: block;
            opacity: .9;
        }

        &:not(.no-link) {

        
            @include hover() {
                img {
                    opacity: .3;
                }

                &:after {
                    bottom: 10px;
                }
            }
        }
    }
}
.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 30px;
    padding-top: 30px;
    margin-top: -100px;

    .heading--small {
        margin-bottom: 0;
        font-weight: bold;
    }

    > a {
        font-weight: bold;
        transition: opacity .3s ease-out;
        @include hover() {
            opacity: .6;
        }
    }
    .footer__social {
        a {
            margin-left: 15px;

            
        }
    }
    @media #{$mqMobile} {
        flex-wrap: wrap;
        align-items: flex-start;
        .heading--small {
            line-height: 1.2;
            padding-top: 20px;
        }
        .footer__social {
            padding-top: 20px;
            flex-basis: 100%;
            justify-content: space-between;
            a {
                margin-left: 0;
            }
        }
    }
}

.portfolio {
    .heading--normal a {
        &:hover,
        &:active {
            text-decoration: underline;
        }
    }
}
