/**
*  --------------------------------
*  project/BASE
*  --------------------------------
*/

@import 'config/index.scss';

$theme: 'bcf-black';
$backgroundColor: $black;
$secondaryColor: $grayMedium;   
$color: $white;
$accentColor: $gray;
// $imageSlideGradient: linear-gradient(to bottom, rgba(197,198,199,0.8) 0%, rgba(197,198,199,0.6) 40%, rgba(197,198,199,0.6) 60%, rgba(197,198,199,0.8) 100%);
$imageSlideGradient: linear-gradient(to bottom, rgba(darken(#002751, 10%),0.4) 0%, rgba(darken(#002751, 10%), 0) 40%, rgba(darken(#002751, 10%), 0) 60%, rgba(darken(#002751, 10%),0.4) 100%);
$navBorder: 1px solid rgba(0,0,0,0.1);
$navBorderInvert: 1px solid rgba($borderInvert, .15);

@import 'ventures.scss';
