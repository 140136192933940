// Fonts
@mixin small-title() {
    font-size: .8125rem;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
    @content;

    @media #{$mqLarge} {
        font-size: 1rem;    
    }
}

@mixin normal-title() {
    font-size: 1.5rem;
    text-transform: none;
    line-height: 1.25;
}

@mixin large-title() {
    // font-size: 5.625rem;
    line-height: 1.11111;
    font-weight: 300;
    text-transform: none;

    @include fluid-type($bpMobileP, $bpDesktopNormal, $font-size-title-lg, $font-size-title-xl);    
    
    @media #{$mqLarge} {
        // font-size: 5.625rem;    
    }

    @media #{$mqMobile} {
        // font-size: 3.125rem;
    }
}

@mixin medium-title() {
    font-size: 2.813rem;
    line-height: 1.12;
    text-transform: none;
    
    @media #{$mqMobile} {
        font-size: 1.875rem;
    }
}


// Spacing
@mixin flexCenter() {
    display: flex;
    align-items: center;
    justify-content: center;
}



// hover
@mixin textHover($color) {
    transition: color 0.4s ease-out;
    
    .no-touchevents &:hover,
    .no-touchevents &:focus, {
        color: $color
    }
}

@mixin hoverColor($color, $backgroundColor) {
    transition: color 0.4s ease-out, background 0.4s ease-out;

    @include hover() {
        color: $color;
        background-color: $backgroundColor;
    }
}

@mixin hover($no-touch-only: false) {
    @if ($no-touch-only == true) {
      .no-touchevents &:hover {
        @content;
      }
    } @else {
      .no-touchevents &:hover,
      .no-touchevents &:focus,
      .touchevents &:active {
        @content;
      }
    }
  }

// Elements
@mixin bullet() {
    content: '';
    display: block;
    cursor: pointer;
    width: 8px;
    min-width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $color;
    transition: $opacityTransition;
    opacity: 0.2;

    &.is-active, .is-active:not(.carousel__slide) & {
        opacity: 1;
    }
}

// placeholder with vendor prefixes
@mixin placeholder {
    $prefixes: ':-webkit-input' ':-moz' '-moz' '-ms-input';
  
    @each $prefix in $prefixes {
      &:#{$prefix}-placeholder {
        @content;
      }
    }
  }




/* ==========================================================================
   keep-ratio / tools
   ========================================================================== */

@mixin keep-ratio($ratio: '1/1') {
    $ratio1: str-slice($ratio, 1, (str-index($ratio, '/') - 1));
    $ratio2: str-slice($ratio, (str-index($ratio, '/') + 1));

    position: relative;

    &::before {
        content: '';
        display: block;
        width: 100%;
        padding-top: calc(#{$ratio2} * 100 / #{$ratio1} * 1%);
    }

    & > img {
        
        bottom: 0;
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
    }
} 

@mixin fluid-type($min-bp, $max-bp, $min-font-size, $max-font-size) {
    $u1: unit($min-bp);
    $u2: unit($max-bp);
    $u3: unit($min-font-size);
    $u4: unit($max-font-size);
  
    @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
      & {
        font-size: $min-font-size;
  
        @media screen and (min-width: $min-bp) {
          font-size: calc(
            #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
              ((100vw - #{$min-bp}) / #{strip-unit($max-bp - $min-bp)})
          );
        }
  
        @media screen and (min-width: $max-bp) {
          font-size: $max-font-size;
        }
      }
    }
  }
  
  @function strip-unit($number) {
    @if type-of($number) == 'number' and not unitless($number) {
      @return $number / ($number * 0 + 1);
    }
  
    @return $number;
  }
  

  @mixin safe-hotspot($width: 44px, $height: 44px, $pseudo: before) {
    position: relative;
  
    &::#{$pseudo} {
      content: '';
      height: $height;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      width: $width;
    }
  }