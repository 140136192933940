/*
 *
 *    Nav Main
 *
 */

.nav--main {
    display: flex;
    
    @media #{$mqDesktop} {
        z-index: 0;
        .nav--main__ul {
            .breadcrumb-is-opened & {
                display: none;
            }
        }
    }

    
    
    &:before {
        content: '';
        background: $backgroundColor;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -2;
    }

    &:after {
        content: '';
        background: #0058b8;
        position: absolute;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100%;
        z-index: -1;
        transition:  $calloutTransition;
        opacity: 0;
    }

    @media #{$mqDesktop} {
        position: fixed;
        left: 0;
        top: 0;
        z-index: 50;
        width: 70%;
        transform: translateY(0);
        transition: $gdprTransition;
            
        .nav__ul {
            max-width: 1000px;
        }
        .nav__inner {
            border-bottom: $navBorder;
            padding-left: 180px;
        }

        .gdpr-visible & {
            transform: translateY($gdprHeight);
        }
    }

    @media #{$mqTablet} {
        height: calc(100% - #{$navHeight});
        overflow: visible;
        // width: 200%;
        // position: relative;

        .gdpr-visible & {
            margin-top: $gdprHeight;
        }

        .nav__inner {
            overflow: auto;
            padding-bottom: 20px;
            height: 100%;
            // overflow: scroll;
            // -webkit-overflow-scrolling: touch;
        
        }
        .nav__ul {
            flex-direction: column;
            margin-top: 15px;
            width: 100%;
            
            li {
                
                width: 100%;
            }
        }
        .nav__link {
            padding: 25px 0;
        }
        .link--bullet {
            display: flex;
            justify-content: flex-start;
            width: 100%; 
            &:after {
                bottom: auto;
                top: 50%;
                transform: translateY(-50%);
                right: 0;
                left: auto;
            }
        }
    }

    @media #{$mqMobile} {
        height: calc(100% - #{$navHeightMobile});
    }

    

}