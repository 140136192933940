/*
 *
 *    Nav Sub menu
 *
 */

.nav__sub {
    position: fixed;
    padding: $largePadding 0;
    background: $backgroundColor;
    z-index: -5;

    .heading {
        font-weight: bold;
        color: $secondaryColor;
    }


    li {
        a {
            display: block;
            padding: 15px 0;
        }
    }
    
    @media #{$mqDesktop} {
        .is-animating & {
            display: none;
        }

        li {
            &:last-of-type {
                margin-bottom: 90px; //ie fix
                @supports (margin-bottom: 0) {
                    margin-bottom: 0;
                }
            }
        }
        
    }
    
    @media #{$mqTablet} {
        display: none;
        right: 0;
        top: 0;
        padding: 0 0 $largePadding 0;
        min-height: 100%;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        height: calc(100% - #{$navHeight});
        width: 100%;
        transform: translateX(100%);

        .gdpr-visible & {
            margin-top: $gdprHeight;
            padding-bottom: 120px;
        }
        
        .nav__link:first-of-type {
            padding: 40px 0;
            border-bottom: $navBorder;
        }

        // .has-sub-nav.is-opened  & {
        // }
        // li {
        //     margin-bottom: 30px;
            
        // }
    }

    @media #{$mqMobile} {
        height: calc(100% - #{$navHeightMobile});
    }
    
    @media #{$mqDesktop} {
        height: calc(100vh - #{$navHeight});
        
        top: $navHeight - 2px;
        transform: translateY(-100%);
        left: 0;
        width: 70%;
        overflow: hidden;

        .gdpr-visible & {
            height: calc(100vh - #{$navHeight + $gdprHeight});
            margin-top: $gdprHeight;
            padding-bottom: 120px;
        }

        @supports (width: 100%) {
            width: 100%;
        }


        
    }

    .heading--small {
        margin-bottom: $largePadding/2;

        @media #{$mqTablet} {
            margin-top: $largePadding;
            font-weight: bold;
            color: $secondaryColor;
            
        }
    }

   
    a {
        transition: transform 0.3s ease-out;
        &:hover, &:focus {
            display: block;
            transform: translateX(15px);
        }
    }

    
}

/*
 *
 * States
 *
 */
@media #{$mqDesktop} {
    .has-sub-nav .nav__sub{
        transition: transform 0.4s ease-in-out;
        .wrap {
            opacity: 0;
            transition: opacity 1s ease-in-out;   
        }
    }   
    .has-sub-nav:hover, .has-sub-nav:focus       {

        .nav__sub {
            overflow: auto;
            transform: translateY(0);

            .wrap {
                opacity: 1;
            }
        }
    
        .link--bullet:after {
            opacity: 0.2;
        }
    }
}

@media #{$mqTablet} {
    .has-sub-nav.is-opened {
        .nav__sub {
            display: block;
        }
     }
}