.logo {
    width: 110px;
    color: $backgroundColor;
    position: fixed;
    top: 0;
    z-index: 100;
    height: $navHeight;
    display: flex;
    align-items: center;
    transition: color 0.3s ease-out, $gdprTransition;

    @media #{$mqTablet} {
        z-index: 203;
    }

    .breadcrumb-is-opened & {
        width: 90px;
        overflow: hidden;
    }
    
    .gdpr-visible & {
        transform: translateY($gdprHeight);
    }

    svg {
        fill:$color;
        transition: fill 0.3s ease-out;
    }

    .has-callout & {
        color: $blue;
        svg {
            fill: $white;
        }   
    }

    @media #{$mqDesktop} {
        left: $largePadding;

        @include icon--nav();
        &:after {
            // top: 55px;
            font-size: 14px;
        }
    }

    @media #{$mqTablet} {
        @include icon--chevron-left();
        left: $mediumPadding;
    }

    @media #{$mqMobile} {
        left: $smallPadding;
        height: $navHeightMobile;
    }

    svg {
        width: 110px;
        min-width: 110px;
        height: 120px;
    }

    // .logo__b, .logo__c, .logo__f {
    //     transition: $translateTransition;
    // }

    // .breadcrumb-is-opened & {  
    //     z-index: 1;
    // }

    &:after {
        transition:  $translateTransition;
        transition-delay: 0.1s;
        position: absolute;
        transform: translate(100%, -48%);
        right: 0;
        display: none;
        top: 50%;

       

        .is-animating &, .breadcrumb-is-opened &,  .is-opened &  {
            display: block;

        }
        .sub-is-opened &, .breadcrumb-is-opened & {  
            transition-delay: 0.4s;
            transform: translate(-62px, -48%);
            @media #{$mqTablet} {
                transform: translate(-87px, -48%);   
            }
        }
    }


    .logo__tagline {
        position: absolute;
        left: 125px;
        top: 0px;
        width: 89px;
        
        svg {
            width: 100%;
            min-width: 0;
        }
        @media #{$mqMobile} {
            top: -15px;
            // left: 38px;
            // width: 75px;
        }
    }

    // .logo__letter {
    //     position: absolute;
    // }

    // .logo__b {
    //     transition-delay: 0.4s; 
    //     .sub-is-opened &, .breadcrumb-is-opened & { 
    //         transition-delay: 0.1s; 
    //         transform: translateX(-25px);
    //     }
    // }

    // .logo__c {
    //     transition-delay: 1;
    //     transition-delay: 0.3s; 
    //     .sub-is-opened &, .breadcrumb-is-opened & {  
    //         transition-delay: 0.2s; 
    //         transform: translateX(-50px);
    //     }
    // }

    // .logo__f {
    //     transition-delay: 0.2s; 
    //     .sub-is-opened &, .breadcrumb-is-opened & {  
    //         transition-delay: 0.3s; 
    //         transform: translateX(-70px);
    //     }
    // }
}