.section {
    display: flex;
    flex-direction: column;

    padding-bottom: $sectionMarginBottom;

    @media #{$mqMobile} {
        padding-bottom: 80px;
    }
    
    &:first-child {
        padding-top: $sectionMarginBottom;
        @media #{$mqMobile} {
            padding-top: $largePadding;
        }
    }

    .section__heading {
        $titlePadding: 15px;
        margin-bottom: $largePadding;
        position: relative;   
        
        .heading {
            @extend .bold;
            padding-right: $titlePadding; 
            position: relative;
            display: inline-block;
            background-color: $backgroundColor;
            z-index: 1;
        }

        
        &:before, &:after {
            content: '';    
            height: 1px;
            background: rgba(0,0,0,0.1);
            position: absolute;
            top: 50%;
        }
        &:after {  
            left: 0;
            width: calc(100% + #{$titlePadding*2});
        }
        &:before {
            width: $titlePadding;
            left: -$titlePadding*2;
        }

        &--small {
            &:after {  
                width: 100%;
            }
        }

        &--with-description {
            &:after {
                top: 100%;
                width: 100%;
            }
            &:before {
                content:none;
            }

            .heading--medium {
                font-weight: normal;
                padding-bottom: $largePadding;
            }
            p {
                padding-bottom: $largePadding;
                margin-bottom: 0;
            }
        }

        @media #{$mqMobile} {
           &:after {
                width: 100%;
            }
            &:before {
                display: none;
            }
        }
    }
}

.section__widened {
    width: 69.5vw;
    margin-left: 10.4vw;
    z-index: 5;
    // max-width: 1600px;
    img {
        width: 100%;
    }

    @media #{$mqExtraLarge} {
        margin: 120px 0 120px -60px;
        width: calc(100vw - 60px);
        padding-left: 5vw;
        padding-right: 5vw;
    }

    @media #{$mqTablet} {
        width: 100%;
        margin-left: 0; 
    }

    @media screen and (min-width: $bpDesktop) {
        .rich-text > * {
            width: 100% !important;
        }
    }

}
