
.js {
    /* Animation */
    .js-will-reveal {
        opacity: 0;
    }    
     
        .rich-text > *, 
        .page-list {
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.6s ease-out, transform 0.6s ease-out;
            transform: translateY(20px);
            position: relative;
        
            &[data-animation-observer*="intersecting"] {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
            }
        }
        
        [data-animation-observer*="from-bottom"] {
            opacity: 0;
            transition: opacity 0.6s ease-out, transform 0.6s ease-out;
            transform: translateY(20px);
            
            &[data-animation-observer*="intersecting"] {
                transform: translateY(0);
                opacity: 1;
            }
        }
    
}
