/**
 *  -----------------------------------------------
 *  shared/GRID
 *  v 1.1
 *
 *  1. Public mixins
 *  2. Variables
 *  3. Grid setup
 *  4. Grid modifiers
 *  5. Private mixins
 *  6. Widths
 *  7. Push
 *  8. Pull
 *  9. Initialisation
 *
 *  -----------------------------------------------
 */

    
    $breakpoints: (
        'mobile' $mqMobile,
        'tabletp' $mqTabletP,
        'tablet' $mqTablet,
        'desktop' $mqDesktop,
        'desktop-normal' $mqDesktopNormal,
        // 'hd' $mqHd,
    ) !default;
    
    $breakpointHasWidths: ('desktop', 'desktop-normal', 'tablet', 'tabletp', 'mobile') !default;
    $breakpointHasPush:   ('desktop', 'desktop-normal', 'tablet', 'tabletp', 'mobile') !default;
    $breakpointHasPull:   ('desktop', 'desktop-normal', 'tablet', 'tabletp', 'mobile') !default;
    
 
    // Generate classes for push/pull shim
    $push: true;
    $pull: false;


    /*
     * 1. Public mixins
     ****************************************************************/
        /*
         *  Modifies gutters for a specific grid. Can be called from any grid in any css.
         **/
        @mixin gGutter($gutter) {
            // $half: $gutter / 2;
            
            @media #{$mqDesktop} {
                margin-left: -$gutter;
            }

            @media #{$mqTablet} {
                margin-left: -$mediumPadding;
            }

            @media #{$mqMobile} {
                margin-left: -$smallPadding;
            }

            > .g_item {
                @media #{$mqDesktop} {
                    padding-left: $gutter;
                }
                @media #{$mqTablet} {
                    padding-left: $mediumPadding;
                }
                @media #{$mqMobile} {
                    padding-left: $smallPadding;
                }
            }

            &.g_full_gutter {
                margin-top: -$gutter;
                // @media #{$mqTablet} {
                //     margin-top: -$mediumPadding;
                // }
                // @media #{$mqMobile} {
                //     margin-top: -$smallPadding;
                // }
                > .g_item {
                    // @media #{$mqDesktop} {
                        margin-top: $gutter;
                    // }
                    // @media #{$mqTablet} {
                    //         margin-top: $mediumPadding;
                    // }
                    // @media #{$mqMobile} {
                    //         margin-top: $smallPadding;
                    // }
                }
            }
            
            /*margin-left: -$half;
            margin-right: -$half;
            > .g_item {
                padding-left: $half;
                padding-right: $half;
                }*/
        };

    /*
     * 2. Variables
     ****************************************************************/
    
        $gutter: $largePadding;
        
    
    /*
     * 3. Grid setup
     ****************************************************************/
        
        .g {
            list-style: none; // can be used on list
            @include gGutter($gutter);
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            //width: 100%;
            
            &--center {
                align-items: center;
            }
        }
        
        .g_item {
            box-sizing: border-box;
            // display: inline-block;
            // vertical-align: top;
            
        }
        
    /*
     * 4. Grid modifiers
     *
     *  1. Gutterless
     *  2. Right aligned
     *  3. Centered
     *  
     *  
     ****************************************************************/

        /* 1. Gutterless grids. Extends '.g' */
            .g_full {
                margin-left: 0;

                > .g_item { padding-left: 0; }
            }
            
            
        /* 2. Reversed grid. Extends '.g' */
            .g_reversed {
                direction: rtl;
                text-align: left;

                > .g_item {
                    direction: ltr;
                    text-align: left;
                }
                
                &.g_float .g_item { float: right; }
            }
            
        /* 3. Narrower gutter grid. Extends '.g' */
            .g_narrow { @include gGutter($gutter / 2); }
        
        
        /* 4. Narrower gutter grid. Extends '.g' */
            .g_wide { @include gGutter($gutter * 2); }
        

        /*
         *  The modifiers below do not work with a floating grid (.g_float)
         */
        

        /* 5. Right aligned grid. Extends '.g' */
            .g_right {
                text-align: right;

                > .g_item { text-align: left; }
            }


        /* 6. Right aligned grid. Extends '.g' */
            .g_center {
                text-align: center;
                
                > .g_item { text-align: left; }
            }


        /* 7. Middle aligned grid (vertical). Extends '.g' */
            .g_middle > .g_item { vertical-align: middle; }


        /* 8. Bottom aligned grid (vertical). Extends '.g' */
            .g_bottom > .g_item { vertical-align: bottom; }
            
        /* 9. Equal height columns. Extends '.g' */
            .g_equalheight {
                display: table;
                > .g_item {
                    display: table-cell;
                    vertical-align: top;
                    height: 100%;
                    }
            }


    /*
     * 6. Private mixins
     *  
     ****************************************************************/
        @mixin grid-media-query($media-query) {
            $breakpoint-found: false;
        
            @each $breakpoint in $breakpoints {
                $name: nth($breakpoint, 1);
                $declaration: nth($breakpoint, 2);
        
                @if $media-query == $name and $declaration {
                    $breakpoint-found: true;
        
                    @media #{$declaration} {
                        @content;
                    }
                }
            }
        
            @if not $breakpoint-found {
                @warn "Breakpoint ‘#{$media-query}’ does not exist";
            }
        }



    /*
     * 5. Widths
     *  
     ****************************************************************/
        @mixin grid-setup($namespace: '') {
        
            /**
             * Whole
             */
            #{$namespace}g_1 { width: 100%; }
                
            /**
             * Halves
             */
            #{$namespace}g_1_2 { width: 50%; }
            
            /**
             * Thirds
             */
            #{$namespace}g_1_3 { width: 33.33333333%; }
            #{$namespace}g_2_3 { width: 66.666%; }
            
            /**
             * Quarters
             */
            #{$namespace}g_1_4 { width: 25%; }
            #{$namespace}g_2_4 { width: 50%; }
            #{$namespace}g_3_4 { width: 75%; }
            
            /**
             * Fifths
             */
            #{$namespace}g_1_5 { width: 20%; }
            #{$namespace}g_2_5 { width: 40%; }
            #{$namespace}g_3_5 { width: 60%; }
            #{$namespace}g_4_5 { width: 80%; }
            
            /**
             * Sixths
             */
            #{$namespace}g_1_6 { width: 16.666%; }
            #{$namespace}g_2_6 { width: 33.33333333%; }
            #{$namespace}g_3_6 { width: 50%; }
            #{$namespace}g_4_6 { width: 66.666%; }
            #{$namespace}g_5_6 { width: 83.333%; }
            
            /**
             * Seventh
             */
            #{$namespace}g_1_7 { width: 14.285%; }
            #{$namespace}g_2_7 { width: 28.571%; }
            #{$namespace}g_3_7 { width: 42.857%; }
            #{$namespace}g_4_7 { width: 57.142%; }
            #{$namespace}g_5_7 { width: 71.428%; }
            #{$namespace}g_6_7 { width: 85.714%; }
            
            /**
             * Eighths
             */
            #{$namespace}g_1_8 { width: 12.5%; }
            #{$namespace}g_2_8 { width: 25%; }
            #{$namespace}g_3_8 { width: 37.5%; }
            #{$namespace}g_4_8 { width: 50%; }
            #{$namespace}g_5_8 { width: 62.5%; }
            #{$namespace}g_6_8 { width: 75%; }
            #{$namespace}g_7_8 { width: 87.5%; }
            
            /**
             * Tenths
             */
            #{$namespace}g_1_10 { width: 10%; }
            #{$namespace}g_2_10 { width: 20%; }
            #{$namespace}g_3_10 { width: 30%; }
            #{$namespace}g_4_10 { width: 40%; }
            #{$namespace}g_5_10 { width: 50%; }
            #{$namespace}g_6_10 { width: 60%; }
            #{$namespace}g_7_10 { width: 70%; }
            #{$namespace}g_8_10 { width: 80%; }
            #{$namespace}g_9_10 { width: 90%; }
            
            /**
             * Twelfts
             */
            #{$namespace}g_1_12 { width: 8.333%; }
            #{$namespace}g_2_12 { width: 16.666%; }
            #{$namespace}g_3_12 { width: 25%; }
            #{$namespace}g_4_12 { width: 33.33333333%; }
            #{$namespace}g_5_12 { width: 41.666%; }
            #{$namespace}g_6_12 { width: 50%; }
            #{$namespace}g_7_12 { width: 58.333%; }
            #{$namespace}g_8_12 { width: 66.666%; }
            #{$namespace}g_9_12 { width: 75%; }
            #{$namespace}g_10_12 { width: 83.333%; }
            #{$namespace}g_11_12 { width: 91.666%; }
        }
        
        
    /*
     * 6. Push
     *  
     ****************************************************************/
        @mixin push-setup($namespace: '') {
            $prefix: '.push_' + $namespace;
            
            /**
             * Whole
             */
            #{$prefix}g_1 { left: 100%; }
                
            /**
             * Halves
             */
            #{$prefix}g_1_2 { left: 50%; }
            
            /**
             * Thirds
             */
            #{$prefix}g_1_3 { left: 33.33333333%; }
            #{$prefix}g_2_3 { left: 66.666%; }
            
            /**
             * Quarters
             */
            #{$prefix}g_1_4 { left: 25%; }
            #{$prefix}g_2_4 { left: 50%; }
            #{$prefix}g_3_4 { left: 75%; }
            
            /**
             * Fifths
             */
            #{$prefix}g_1_5 { left: 20%; }
            #{$prefix}g_2_5 { left: 40%; }
            #{$prefix}g_3_5 { left: 60%; }
            #{$prefix}g_4_5 { left: 80%; }
            
            /**
             * Sixths
             */
            #{$prefix}g_1_6 { left: 16.666%; }
            #{$prefix}g_2_6 { left: 33.33333333%; }
            #{$prefix}g_3_6 { left: 50%; }
            #{$prefix}g_4_6 { left: 66.666%; }
            #{$prefix}g_5_6 { left: 83.333%; }
            
            /**
             * Seventh
             */
            #{$prefix}g_1_7 { left: 14.285%; }
            #{$prefix}g_2_7 { left: 28.571%; }
            #{$prefix}g_3_7 { left: 42.857%; }
            #{$prefix}g_4_7 { left: 57.142%; }
            #{$prefix}g_5_7 { left: 71.428%; }
            #{$prefix}g_6_7 { left: 85.714%; }
            
            /**
             * Eighths
             */
            #{$prefix}g_1_8 { left: 12.5%; }
            #{$prefix}g_2_8 { left: 25%; }
            #{$prefix}g_3_8 { left: 37.5%; }
            #{$prefix}g_4_8 { left: 50%; }
            #{$prefix}g_5_8 { left: 62.5%; }
            #{$prefix}g_6_8 { left: 75%; }
            #{$prefix}g_7_8 { left: 87.5%; }
            
            /**
             * Tenths
             */
            #{$prefix}g_1_10 { left: 10%; }
            #{$prefix}g_2_10 { left: 20%; }
            #{$prefix}g_3_10 { left: 30%; }
            #{$prefix}g_4_10 { left: 40%; }
            #{$prefix}g_5_10 { left: 50%; }
            #{$prefix}g_6_10 { left: 60%; }
            #{$prefix}g_7_10 { left: 70%; }
            #{$prefix}g_8_10 { left: 80%; }
            #{$prefix}g_9_10 { left: 90%; }
            
            /**
             * Twelfts
             */
            #{$prefix}g_1_12 { left: 8.333%; }
            #{$prefix}g_2_12 { left: 16.666%; }
            #{$prefix}g_3_12 { left: 25%; }
            #{$prefix}g_4_12 { left: 33.33333333%; }
            #{$prefix}g_5_12 { left: 41.666%; }
            #{$prefix}g_6_12 { left: 50%; }
            #{$prefix}g_7_12 { left: 58.333%; }
            #{$prefix}g_8_12 { left: 66.666%; }
            #{$prefix}g_9_12 { left: 75%; }
            #{$prefix}g_10_12 { left: 83.333%; }
            #{$prefix}g_11_12 { left: 91.666%; }
        }
    
    
    /*
     * 7. Pull
     *  
     ****************************************************************/
    
        @mixin pull-setup($namespace: '') {
            $prefix: '.pull_'+ $namespace;
            
            /**
             * Whole
             */
            #{$prefix}g_1 { right: 100%; }
                
            /**
             * Halves
             */
            #{$prefix}g_1_2 { right: 50%; }
            
            /**
             * Thirds
             */
            #{$prefix}g_1_3 { right: 33.33333333%; }
            #{$prefix}g_2_3 { right: 66.666%; }
            
            /**
             * Quarters
             */
            #{$prefix}g_1_4 { right: 25%; }
            #{$prefix}g_2_4 { right: 50%; }
            #{$prefix}g_3_4 { right: 75%; }
            
            /**
             * Fifths
             */
            #{$prefix}g_1_5 { right: 20%; }
            #{$prefix}g_2_5 { right: 40%; }
            #{$prefix}g_3_5 { right: 60%; }
            #{$prefix}g_4_5 { right: 80%; }
            
            /**
             * Sixths
             */
            #{$prefix}g_1_6 { right: 16.666%; }
            #{$prefix}g_2_6 { right: 33.33333333%; }
            #{$prefix}g_3_6 { right: 50%; }
            #{$prefix}g_4_6 { right: 66.666%; }
            #{$prefix}g_5_6 { right: 83.333%; }
            
            /**
             * Seventh
             */
            #{$prefix}g_1_7 { right: 14.285%; }
            #{$prefix}g_2_7 { right: 28.571%; }
            #{$prefix}g_3_7 { right: 42.857%; }
            #{$prefix}g_4_7 { right: 57.142%; }
            #{$prefix}g_5_7 { right: 71.428%; }
            #{$prefix}g_6_7 { right: 85.714%; }
            
            /**
             * Eighths
             */
            #{$prefix}g_1_8 { right: 12.5%; }
            #{$prefix}g_2_8 { right: 25%; }
            #{$prefix}g_3_8 { right: 37.5%; }
            #{$prefix}g_4_8 { right: 50%; }
            #{$prefix}g_5_8 { right: 62.5%; }
            #{$prefix}g_6_8 { right: 75%; }
            #{$prefix}g_7_8 { right: 87.5%; }
            
            /**
             * Tenths
             */
            #{$prefix}g_1_10 { right: 10%; }
            #{$prefix}g_2_10 { right: 20%; }
            #{$prefix}g_3_10 { right: 30%; }
            #{$prefix}g_4_10 { right: 40%; }
            #{$prefix}g_5_10 { right: 50%; }
            #{$prefix}g_6_10 { right: 60%; }
            #{$prefix}g_7_10 { right: 70%; }
            #{$prefix}g_8_10 { right: 80%; }
            #{$prefix}g_9_10 { right: 90%; }
            
            /**
             * Twelfts
             */
            #{$prefix}g_1_12 { right: 8.333%; }
            #{$prefix}g_2_12 { right: 16.666%; }
            #{$prefix}g_3_12 { right: 25%; }
            #{$prefix}g_4_12 { right: 33.33333333%; }
            #{$prefix}g_5_12 { right: 41.666%; }
            #{$prefix}g_6_12 { right: 50%; }
            #{$prefix}g_7_12 { right: 58.333%; }
            #{$prefix}g_8_12 { right: 66.666%; }
            #{$prefix}g_9_12 { right: 75%; }
            #{$prefix}g_10_12 { right: 83.333%; }
            #{$prefix}g_11_12 { right: 91.666%; }
        }
    
    
    
    /*
     * 8. Initialisation
     *  
     ****************************************************************/
    
        // Base grid
        @each $name in $breakpointHasWidths {
            @if $name == 'desktop' {
                @include grid-setup('.');
            } @else {
                @include grid-media-query($name) {
                    @include grid-setup('.#{$name}_');
                }
            }
        }
        
        // Push
        @if $push {
            [class*="push_"] { position:relative; }
            @include push-setup;
        
            @each $name in $breakpointHasPush {
                @if $name == 'desktop' {
                    @include push-setup('');
                } @else {
                    @include grid-media-query($name) {
                        @include push-setup('#{$name}_');
                    }
                }
            }        
        }


        // Pull
        @if $pull {
            [class*="pull_"] { position:relative; }
        
            @include pull-setup;
        
            @if $responsive {
                @each $name in $breakpoint-has-pull {
                    @if $name == 'desktop' {
                         @include pull-setup('');
                    } @else {
                        @include grid-media-query($name) {
                            @include pull-setup('#{$name}_');
                        }
                    }
                }
            }
        
        }