/**
 *  -----------------------------------------------
 *  shared/GLOBAL
 *
 *  1. Base
 *  2. Headings
 *  3. Text
 *  4. Links/buttons
 *  5. Custom fixes
 *  6. Accessibility
 *  -----------------------------------------------
 */

/*
     * 1. Base
     ****************************************************************/

     * {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; 
        ::selection { background: $grayDark; color: $white; }
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    
    *,
    *:before,
    *:after {
        box-sizing: border-box;
    }
    
    html,
    body {
        height: 100%;
    }
    
    :root {
        font-size: 16px;
    }
    
    body {
        background-color: $backgroundColor; 
        color: $color;
        min-height: 100%;
        font-family: $fontBasis;
        // font-size: 1.125rem;
        font-size: 1rem;
    
        html.has-callout & {
            color: $white;
        }


        overflow-x: hidden; //ie
        @supports (overflow: auto) {
            overflow: auto;
        }
    
        // Add pseudo element to body to be able to sync breakpoint with JS
        &:after {
            content: "none";
            display: none;
            /* Prevent from displaying. */
        }
    
        @media #{$mqLarge} {
            font-size: 1.125rem;
        }
    
        @media #{$mqDesktop} {
            &:after {
                content: "desktop";
            }
        }
        
        @media #{$mqTablet} {
            &:after {
                content: "tablet";
            }
        }
        
        @media #{$mqMobile} {
            &:after {
                content: "mobile";
            }
        }
    
    
        /* When an overlay is active */
        .overflow-is-hidden &,
        .overflow-is-hidden & .wrapper {
            height: 100%;
            overflow: hidden;
        }
    
         
    } 
    
    // Main content wrapper (excluding header/footer)
    .main {
        margin: 0 auto;
        min-height: 100vh;
        width: 100%;
        overflow-x: hidden;
        transition: top .4s ease-out;
        position: relative;
        top: 0;
        &.two-cols {
            // display:flex;
            //     overflow-x : unset;
            //     align-items: flex-start;
                
            overflow-x : unset;
    
            .CoveoSearchInterface {
                min-height: 100vh;
                overflow-x : unset;
                position: unset;
                min-width: 0;
                align-items: stretch;

                @media #{$mqDesktop} {
                    display:flex!important;
                }
    
                .coveo-search-interface-mandatory-section, .CoveoForSitecoreContext {
                    display: none;
                }
                .left-pannel {
                    display: block;

                    @media #{$mqTablet} {
                        overflow-x: hidden;
                    }
                }

                
            }
            
        }
    
        .gdpr-visible & {
            top: $gdprHeight;
        }
    } 
    
    /** Wrapping container **/
    .wrap {
        margin: 0 auto;
        padding: 0 $largePadding;
        position: relative;
        // max-width: $bpDesktop; // Can be max-width with min-width
        width: 100%;
    
        @media #{$mqTablet} {
            width: auto;
            padding: 0 $mediumPadding;
        }
    
        @media #{$mqMobile} {
            padding: 0 $smallPadding;
        }
    
        &--no-overflow {
            overflow: hidden;
        }
    }
    
    .left-pannel {
        width: $leftPannelWidth;
        @media #{$mqTablet} {
            width: 100%;
        }
        
    }
    
    .right-pannel {
        width: $rightPannelWidth;
        @media #{$mqTablet} {
            width: 100%;
        }
    }
    
    /*
     * 2. Headings
     ****************************************************************/
    h1,
    h2,
    h3,
    h4,
    h5 {
        outline: none;
    }
    
    .heading {
        &--large {
            @include large-title();
        }
        &--medium {
            @include medium-title();
        }
        &--normal {
            @include normal-title();
        }
        &--small {
            @include small-title();
        }
    }
    
    .heading--module {
        margin-bottom: $xlPadding;
        width: 70%;
    
        @media #{$mqMobile} {
            margin-bottom: $largePadding;
        }
    }
    
    
    /* Text */
    p {
        line-height: 1.77778;
        margin-bottom: 20px;
    }
    
    p, .rich-text li {
        a {
            text-decoration: underline;
        }
    }
    
    strong, .bold {
        font-weight: bold;
    }
    
    em, .italic {
        font-style: italic;
    }
    
    sup, 
    sub {
        line-height: 1;
        height: 0;
        position: relative;
        font-size: 0.7em;   
        margin-left: 0.2em;
    }
    
    sup {
        vertical-align: top;
    }
    
    sub {
        vertical-align: bottom;
    }
    
    hr {
        border: none;
        border-bottom: 1px solid #000;
    }
    
    .fine {
        letter-spacing: -0.2em;
    }
    
    .align_center {
        text-align: center;
    }
    
    img {
        height: auto;
        max-width: 100%;
    }
    
    figcaption {
        font-size: 0.75rem;
        opacity: 0.6;
    }
    
    svg {
        overflow: visible;
    }
    
    
    
    
    
    .gdpr, .msg {
        background: $blue;
        color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: $gdprHeight;
        position: fixed; 
        width: 100%;
        z-index: 200;
        transform: translateY(-$gdprHeight);
        transition: $gdprTransition;
        span {
            font-family: $fontBasis;
            font-size: 1.3rem;
            padding-right: 15px;
            a {
                color: white;
                text-decoration: underline;
    
                @include hover() {
                    text-decoration: none;
                }
            }
        }
        .gdpr-visible & {
            transform: translateY(0px);    
        }
        .icon_close {
            color: $white;
            transition: transform .4s ease-out;
            transform: rotate(0);
            height: 20px;
            width: 60px;
            @include hover() {
                transform: rotate(90deg);
            }
        }
    
        @media #{$mqMobile} {
            padding: 20px;
          span { font-size: 14px; line-height: 1.2; }
        }
        
    }
    .msg {
        bottom: 0;
        left: 0;
        transform: translateY(0);
    }